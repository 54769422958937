import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { map, reduce } from 'lodash'
import {
  Formik, Form, FieldArray, Field,
} from 'formik'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import styled from 'styled-components'
import {
  Button as MuiButton, Checkbox as MuiCheckbox, Radio as MuiRadio, Typography,
} from '@material-ui/core'
import {
  Dialog, DialogContent, Modal, Loader, TextField,
} from '@/components'
import { closeWebView } from '@/libs'
import { clearfix } from '@/styles'


const Wrapper = styled.main`
  height: calc(100vh - 60px);
  padding: 8px 0;
  background-color: #eaecee;
`

const ImageWrapper = styled.div`
  position: relative;
  background-color: #00c896;
`

const SurveyForm = styled(Form)`
`

const StyledPage = styled(Page)`
  & .react-pdf__Page__canvas, & .react-pdf__Page__textContent {
    width: 100% !important;
    height: 100% !important;
  }
`

const SubmitButton = styled(MuiButton)`
  && {
    position: absolute;
    top: -55px;
    right: 16px;
    padding: 0;
    color: rgba(0, 0, 0, 0.26);
    font-size: 16px;
  }
`

const Question = styled.div`
  position: absolute;
  top: ${({ y }) => y}%;
  left: ${({ x }) => x}%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  padding-top: 1px;
  color: #fff;
  border-radius: 50%;
  background-color: #00c896;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
`

const Option = styled.div`
  position: absolute;
  top: ${({ y }) => y}%;
  left: ${({ x }) => x}%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
`

const Checkbox = styled(MuiCheckbox)`
  && {
    padding: 0;
    width: 16px;
    height: 16px;
  }
  & svg {
    width: 12px;
    height: 12px;
  }
`

const Radio = styled(MuiRadio)`
  && {
    padding: 0;
    width: 16px;
    height: 16px;
  }
  & svg {
    width: 12px;
    height: 12px;
  }
`

const OptionNumber = styled.div`
  width: 18px;
  height: 12px;
  border-radius: 1px;
  background-color: #448aff;
  text-align: center;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
`

const ClearfixedDialogContent = styled(DialogContent)`
  ${clearfix};
`

const ConfirmationButton = styled(MuiButton)`
  && {
    position: relative;
    top: 12px;
    left: 18px;
    float: right;
    width: 42px;
    min-width: initial;
    min-height: initial;
    padding: 0;
  }
`

class Main extends Component {
  state = { pageNumber: 1 }

  handleDocumentLoadSuccess = () => {}

  render() {
    const { handleDocumentLoadSuccess } = this
    const { pageNumber } = this.state
    const {
      survey: { questions, blob },
      isCreating,
      isCompleted,
      onSubmit,
    } = this.props
    return (
      <Typography component={Wrapper}>
        <ImageWrapper>
          <Document
            file={blob}
            loading={<Loader />}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <StyledPage
              pageNumber={pageNumber}
            />
          </Document>
          <Formik
            initialValues={reduce(questions, (acc, cur, key) => ({
              ...acc,
              [key]: {
                SUBJECTIVE: '',
                SINGLE_CHOICE: '',
                MULTIPLE_CHOICE: [],
              }[cur.type],
            }), {})}
            onSubmit={values => onSubmit({ input: values })}
            render={({ values, handleSubmit }) => (
              <SurveyForm>
                {map(questions, (question, key) => ({
                  SUBJECTIVE: (
                    <div key={question.number}>
                      <Question
                        x={question.offsetX}
                        y={question.offsetY}
                      >
                        {question.number}
                      </Question>
                      {question.field && (
                        <Field name={key}>
                          {({ field }) => (
                            <TextField
                              id={`SUBJECTIVE-${key}`}
                              label={`SUBJECTIVE-${key}`}
                              InputLabelProps={{ shrink: true }}
                              required
                              x={question.field.offsetX}
                              y={question.field.offsetY}
                              {...field}
                              name={String(field.name)}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                  ),
                  SINGLE_CHOICE: (
                    <div key={question.number}>
                      <Question
                        x={question.offsetX}
                        y={question.offsetY}
                      >
                        {question.number}
                      </Question>
                      <Field name={key}>
                        {({ field, form: { setFieldValue } }) => (
                          map(question.options, (option, index) => (
                            <Option
                              key={option.number}
                              x={option.offsetX}
                              y={option.offsetY}
                            >
                              <OptionNumber>{`${question.number}-${option.number}`}</OptionNumber>
                              <Radio
                                checked={values[key] === String(index)}
                                {...field}
                                value={String(index)}
                                name={String(index)}
                                color="primary"
                                onChange={(e) => { setFieldValue(key, e.target.value) }}
                              />
                            </Option>
                          ))
                        )}
                      </Field>
                    </div>
                  ),
                  MULTIPLE_CHOICE: (
                    <div key={question.number}>
                      <Question
                        x={question.offsetX}
                        y={question.offsetY}
                      >
                        {question.number}
                      </Question>
                      <FieldArray
                        name={key}
                        render={({ push, remove }) => map(question.options, (option, index) => (
                          <Option
                            key={option.number}
                            x={option.offsetX}
                            y={option.offsetY}
                          >
                            <OptionNumber>{`${question.number}-${option.number}`}</OptionNumber>
                            <Checkbox
                              value={String(index)}
                              checked={values[key].includes(index)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  push(index)
                                } else {
                                  const idx = values[key].indexOf(index)
                                  remove(idx)
                                }
                              }}
                              color="primary"
                            />
                          </Option>
                        ))}
                      />
                    </div>
                  ),
                }[question.type]))}
                <Modal>
                  {({ handleOpen, handleClose, open }) => (
                    <>
                      <SubmitButton type="button" onClick={handleOpen}>제출하기</SubmitButton>
                      <Dialog open={open} onClose={handleClose}>
                        <ClearfixedDialogContent>
                          <p>가정통신문을 제출하시겠습니까?</p>
                          <p>중복 응답의 경우, 마지막 응답으로 기록됩니다.</p>
                          <ConfirmationButton
                            type="submit"
                            onClick={(e) => {
                              handleClose()
                              handleSubmit(e)
                            }}
                            color="primary"
                          >
                            제출
                          </ConfirmationButton>
                          <ConfirmationButton
                            onClick={() => {
                              handleClose()
                            }}
                            color="primary"
                          >
                            취소
                          </ConfirmationButton>
                        </ClearfixedDialogContent>
                      </Dialog>
                    </>
                  )}
                </Modal>
                {isCompleted && (
                  <Modal>
                    {({ handleOpen, open }) => (
                      <>
                        <Dialog open={!open} onClose={handleOpen}>
                          <ClearfixedDialogContent>
                            <p>가정통신문이 제출되었습니다.</p>
                            <ConfirmationButton
                              type="submit"
                              onClick={() => {
                                handleOpen()
                                closeWebView()
                              }}
                              color="primary"
                            >
                              확인
                            </ConfirmationButton>
                          </ClearfixedDialogContent>
                        </Dialog>
                      </>
                    )}
                  </Modal>
                )}
              </SurveyForm>
            )}
          />
        </ImageWrapper>
        {isCreating && <Loader />}
      </Typography>
    )
  }
}

Main.propTypes = {
  survey: PropTypes.shape({
    questions: PropTypes.array,
    blob: PropTypes.any,
  }).isRequired,
  isCreating: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Main

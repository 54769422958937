import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Loader } from '@/components'
import Main from './Main'
import { fetchNewsletterRequest, putResponseRequest, initializeNewsletterState } from '../newsletterActions'

const mapStateToProps = ({ newsletter }) => ({
  isLoading: newsletter.isLoading,
  isCreating: newsletter.isCreating,
  isCompleted: newsletter.isCompleted,
  survey: newsletter.survey,
})

const mapDispatchToProps = {
  onMount: fetchNewsletterRequest,
  onUnmount: initializeNewsletterState,
  onSubmit: putResponseRequest,
}

class MainContainer extends Component {
  componentDidMount() {
    const {
      onMount, schoolId, newsletterId, token,
    } = this.props
    onMount({ schoolId, newsletterId, token })
  }

  componentWillUnmount() {
    const { onUnmount } = this.props
    onUnmount()
  }

  render() {
    const { isLoading } = this.props
    return (
      isLoading
        ? <Loader />
        : <Main {...this.props} />
    )
  }
}

MainContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  schoolId: PropTypes.string.isRequired,
  newsletterId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MainContainer)

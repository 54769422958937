import { FETCH_NEWSLETTER_SUMMARY_REQUEST, FETCH_NEWSLETTER_SUMMARY_SUCCESS, FETCH_NEWSLETTER_SUMMARY_FAILURE } from './landingActions'

export const initialState = {
  isLoading: false,
  summary: {},
  error: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NEWSLETTER_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_NEWSLETTER_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summary: payload.summary,
      }
    case FETCH_NEWSLETTER_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    default:
      return state
  }
}

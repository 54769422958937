import React, { Component } from 'react'
import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { closeWebView } from '@/libs'

const Wrapper = styled.header`
  display: flex;
  padding: 16px;
`

const Title = styled.h2`
  order: 1;
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: normal;
`

const Button = styled(MuiButton)`
  && {
    width: 28px;
    min-width: initial;
    height: 28px;
    min-height: initial;
    margin-right: 16px;
    padding: 0;
    border-radius: 50%;
  }
`

class Header extends Component {
  handleClick = () => closeWebView()

  render() {
    const { handleClick } = this
    return (
      <Wrapper>
        <Title>가정통신문</Title>
        <Button type="button" onClick={handleClick}>
          <ArrowBack />
        </Button>
      </Wrapper>
    )
  }
}

export default Header

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { map } from 'lodash'
import { routes } from '@/contexts'
import { theme } from '@/styles'
import '@/styles/globalStyle'
import configureStore from './configureStore'

const store = configureStore()

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          {map(routes, ({ isPrivate, ...routeProps }) => (
            <Route {...routeProps} />
          ))}
        </Switch>
      </Router>
    </MuiThemeProvider>
  </Provider>
)

export default App

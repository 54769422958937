import React from 'react'
import styled from 'styled-components'
import { ctLogo } from '@/assets'
import { CLASSTING_URL } from '@/constants'

const Wrapper = styled.header`
  padding: 12px 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
`

const Title = styled.h1`
  overflow: hidden;
  width: 136px;
  height: 32px;
  padding-top: 32px;
  background: url(${ctLogo});
`

const Header = () => (
  <Wrapper>
    <a href={CLASSTING_URL}>
      <Title>
        Classting
      </Title>
    </a>
  </Wrapper>
)

export default Header

import {
  take, call, put, select,
} from 'redux-saga/effects'
import { removeItem, setTokenToRequestHeader, removeTokenFromRequestHeader } from '@/libs'
import { firebaseSignIn } from '@/services'
import {
  SIGN_IN_REQUEST, signInSuccess, signInFailure,
  SIGN_OUT_REQUEST, signOutSuccess, signOutFailure,
} from './authActions'
import { fetchJwt, fetchUserProfile, destroyToken } from './authServices'
import { getAccessToken } from './authSelectors'

export function* signIn() {
  while (true) {
    try {
      const { payload } = yield take(SIGN_IN_REQUEST)
      setTokenToRequestHeader(payload)
      const token = yield call(fetchJwt)
      yield call(firebaseSignIn, token)
      const { id, email } = yield call(fetchUserProfile)
      const profile = { id, email }
      yield put(signInSuccess({ profile }))
    } catch (e) {
      yield put(signInFailure({ error: { message: e.message } }))
    }
  }
}

export function* signOut() {
  while (true) {
    try {
      yield take(SIGN_OUT_REQUEST)
      removeItem('token_type')
      removeItem('access_token')
      removeItem('expiration_time')
      removeTokenFromRequestHeader()
      const accessToken = yield select(getAccessToken)
      yield call(destroyToken, { accessToken })
      yield put(signOutSuccess())
    } catch (e) {
      yield put(signOutFailure({ error: { message: e.message } }))
    }
  }
}

export default { signIn, signOut }

import styled from 'styled-components'
import { Dialog as MuiDialog } from '@material-ui/core'

const Dialog = styled(MuiDialog)`
  & [role="document"] {
    padding: 24px;
  }
`

export default Dialog

import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@/components'
import Header from './Header'
import MainContainer from './MainContainer'

const Newsletter = ({ match: { params: { schoolId, newsletterId, token } } }) => (
  <Layout header={<Header />}>
    <MainContainer schoolId={schoolId} newsletterId={newsletterId} token={token} />
  </Layout>
)

Newsletter.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default Newsletter

import {
  all, call, put, select, take,
} from 'redux-saga/effects'
import { setTokenToRequestHeader } from '@/libs'
import { FIREBASE_REF } from '@/constants'
import {
  firebaseGetDoc, firebaseGetDownloadURL, firebaseBatch,
  firebaseRunTransaction, firebaseCountTransaction,
} from '@/services'
import { fetchBlob, fetchGuardianContactNewsletter } from './newsletterServices'
import {
  FETCH_NEWSLETTER_REQUEST, fetchNewsletterSuccess, fetchNewsletterFailure,
  PUT_RESPONSE_REQUEST, putResponseSuccess, putResponseFailure,
} from './newsletterActions'
import { getGuardianContactNewsletter } from './newsletterSelectors'

export function* fetchNewsletter() {
  while (true) {
    try {
      const { payload: { schoolId, newsletterId, token } } = yield take(FETCH_NEWSLETTER_REQUEST)
      setTokenToRequestHeader({})
      const [
        surveyDoc,
        url,
        {
          data: [{
            guardian_contact_id: guardianContactId,
            id,
            last_opened_at: lastOpenedAt,
          }],
        },
      ] = yield all([
        call(firebaseGetDoc, { path: `${FIREBASE_REF}/surveys/${newsletterId}` }),
        call(firebaseGetDownloadURL, { path: `${FIREBASE_REF}/schools/${schoolId}/school-newsletters/${newsletterId}.pdf` }),
        call(fetchGuardianContactNewsletter, { token }),
      ])
      const { questions } = surveyDoc.data()
      const blob = yield fetchBlob({ url })
      const survey = { questions, blob }
      const guardianContactNewsletter = {
        guardianContactId, id, lastOpenedAt, newsletterId, schoolId,
      }
      yield put(fetchNewsletterSuccess({ survey, guardianContactNewsletter }))
    } catch (e) {
      yield put(fetchNewsletterFailure({ error: { message: e.message } }))
    }
  }
}

export function* putResponse() {
  while (true) {
    try {
      const { payload: { input } } = yield take(PUT_RESPONSE_REQUEST)
      const {
        guardianContactId, newsletterId,
        // schoolId, lastOpenedAt, id,
      } = yield select(getGuardianContactNewsletter)
      yield all([
        call(firebaseBatch, [
          { method: 'set', path: `${FIREBASE_REF}/individuals/${newsletterId}`, data: { [guardianContactId]: input } },
          { method: 'set', path: `${FIREBASE_REF}/targets/${newsletterId}`, data: { [guardianContactId]: { recorded: true } } },
        ]),
        call(firebaseRunTransaction, {
          path: `${FIREBASE_REF}/stats/${newsletterId}`,
          data: input,
        }),
        call(firebaseCountTransaction, { path: `${FIREBASE_REF}/newsletters/${newsletterId}` }),
      ])
      yield put(putResponseSuccess())
    } catch (e) {
      yield put(putResponseFailure({ error: { message: e.message } }))
    }
  }
}

export default { fetchNewsletter, putResponse }

import { http } from '@/libs'
import { CLASSTING_AUTH_URL, CLASSTING_OPEN_API_URL } from '@/constants'

export const fetchJwt = async () => {
  const { data: { token } } = await http.get(`${CLASSTING_AUTH_URL}/jwt`)

  return token
}

export const fetchUserProfile = async () => {
  const { data } = await http.get(`${CLASSTING_OPEN_API_URL}/v2/users/me`)
  return data
}

export const destroyToken = async ({ accessToken }) => {
  await http.post(
    `${CLASSTING_OPEN_API_URL}/v1/oauth/revoke`,
    {
      access_token: accessToken,
    },
  )
}

import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'

const Button = styled(MuiButton)`
  && {
    width: ${({ width }) => width};
    min-width: initial;
    height: ${({ height }) => height};
    padding: 0;
    color: ${({ variant, coloured }) => (variant === 'outlined' ? coloured : '#fff')};
    border: ${({ variant, coloured }) => (variant === 'outlined' ? `1px solid ${coloured}` : 'none')};
    border-radius: ${({ rounded }) => rounded};
    background-color: ${({ variant, coloured }) => (variant === 'outlined' ? 'transparent' : coloured)};
    &:hover {
      background-color: ${({ variant }) => (variant === 'outlined' ? '#f0faf7' : '#02815e')};
    }
  }
`

Button.defaultProps = {
  rounded: '2px',
  coloured: '#00c896',
  height: '36px',
  width: '90px',
}

export default Button

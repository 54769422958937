import {
  FETCH_NEWSLETTER_REQUEST, FETCH_NEWSLETTER_SUCCESS, FETCH_NEWSLETTER_FAILURE,
  PUT_RESPONSE_REQUEST, PUT_RESPONSE_SUCCESS, PUT_RESPONSE_FAILURE,
  INITIALIZE_NEWSLETTER_STATE,
} from './newsletterActions'

export const initialState = {
  isLoading: false,
  isCreating: false,
  isCompleted: false,
  survey: {},
  guardianContactNewsletter: {},
  error: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NEWSLETTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        survey: payload.survey,
        guardianContactNewsletter: payload.guardianContactNewsletter,
      }
    case FETCH_NEWSLETTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    case PUT_RESPONSE_REQUEST:
      return {
        ...state,
        isCreating: true,
      }
    case PUT_RESPONSE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        isCompleted: true,
      }
    case PUT_RESPONSE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: payload.error,
      }
    case INITIALIZE_NEWSLETTER_STATE:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        isCompleted: false,
        survey: {},
        guardianContactNewsletter: {},
        error: {},
      }
    default:
      return { ...state }
  }
}

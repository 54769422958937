import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'
import { readableHiddenText } from '@/styles'

const StyledTextField = styled(MuiTextField)`
  && {
    position: absolute;
    top: ${({ y }) => y}%;
    left: ${({ x }) => x}%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    background: #fff;
    opacity: .9;
  }
  && > label {
    position: static;
    width: 74px;
    font-size: 18px;
    font-weight: bold;
   ${readableHiddenText}
  }
  && > div {
    margin: 0;
    padding: 0;
  }
  && > div:before, && > div:after {
    display: none;
  }
  && input {
    box-sizing: border-box;
    width: 80px;
    height: 28px;
    padding-left: 10px;
    border: ${({ error }) => (error ? '1px solid #a94442' : '1px solid #bdbdbd')};
    border-radius: 4px;
    font-size: 12px;
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover, &:focus {
      border-color: #00c896;
    }
  }
`

const TextField = ({ field, form: { touched, errors }, ...props }) => (
  <StyledTextField
    {...field}
    {...props}
    error={!!touched[field.name] && !!errors[field.name]}
  />
)

TextField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    erros: PropTypes.object,
  }),
}

TextField.defaultProps = {
  field: {},
  form: { touched: {}, errors: {} },
}

export default TextField

export const FETCH_NEWSLETTER_REQUEST = 'FETCH_NEWSLETTER_REQUEST'
export const FETCH_NEWSLETTER_SUCCESS = 'FETCH_NEWSLETTER_SUCCESS'
export const FETCH_NEWSLETTER_FAILURE = 'FETCH_NEWSLETTER_FAILURE'

export const PUT_RESPONSE_REQUEST = 'PUT_RESPONSE_REQUEST'
export const PUT_RESPONSE_SUCCESS = 'PUT_RESPONSE_SUCCESS'
export const PUT_RESPONSE_FAILURE = 'PUT_RESPONSE_FAILURE'

export const INITIALIZE_NEWSLETTER_STATE = 'INITIALIZE_NEWSLETTER_STATE'

export const fetchNewsletterRequest = payload => ({
  type: FETCH_NEWSLETTER_REQUEST,
  payload,
})
export const fetchNewsletterSuccess = payload => ({
  type: FETCH_NEWSLETTER_SUCCESS,
  payload,
})
export const fetchNewsletterFailure = payload => ({
  type: FETCH_NEWSLETTER_FAILURE,
  payload,
})

export const putResponseRequest = payload => ({
  type: PUT_RESPONSE_REQUEST,
  payload,
})
export const putResponseSuccess = payload => ({
  type: PUT_RESPONSE_SUCCESS,
  payload,
})
export const putResponseFailure = payload => ({
  type: PUT_RESPONSE_FAILURE,
  payload,
})
export const initializeNewsletterState = payload => ({
  type: INITIALIZE_NEWSLETTER_STATE,
  payload,
})

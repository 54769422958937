import { call, put, take } from 'redux-saga/effects'
import { setTokenToRequestHeader } from '@/libs'
import { FETCH_NEWSLETTER_SUMMARY_REQUEST, fetchNewsletterSummarySuccess, fetchNewsletterSummaryFailure } from './landingActions'
import { fetchGuardianContactNewsletter } from './landingServices'

export function* fetchNewsletterSummary() {
  while (true) {
    try {
      const { payload: { token } } = yield take(FETCH_NEWSLETTER_SUMMARY_REQUEST)
      setTokenToRequestHeader({})
      const {
        data: [{
          id, last_opened_at: lastOpenedAt, newsletter_id: newsletterId, school_id: schoolId, title,
        }],
      } = yield call(fetchGuardianContactNewsletter, { token })
      const summary = {
        id, lastOpenedAt, newsletterId, schoolId, title,
      }
      yield put(fetchNewsletterSummarySuccess({ summary }))
    } catch (e) {
      yield put(fetchNewsletterSummaryFailure({ error: { message: e.message } }))
    }
  }
}

export default { fetchNewsletterSummary }

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { forEach, join } from 'lodash'
import { Loader } from '@/components'
import { CLASSTING_CLIENT_ID, CLASSTING_AUTH_URL } from '@/constants'
import { signInRequest } from '@/contexts/auth'
import {
  getDisplayName, queryString, getItem, setItem, getTokenFromNative, closeWebView,
} from '@/libs'

const withAuth = (WrappedComponent) => {
  const mapStateToProps = ({ auth }) => ({
    isAuthenticated: auth.isAuthenticated,
    connectedSchool: auth.connectedSchool,
  })
  const mapDispatchToProps = {
    onMount: signInRequest,
  }

  class EnhancedComponent extends Component {
    static displayName = `withAuth(${getDisplayName(WrappedComponent)})`

    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      connectedSchool: PropTypes.string.isRequired,
      onMount: PropTypes.func.isRequired,
      location: PropTypes.shape({ hash: PropTypes.string }).isRequired,
      match: PropTypes.shape({ params: PropTypes.object }).isRequired,
    }

    componentDidMount() {
      const {
        isAuthenticated, connectedSchool, onMount, location, match: { params: { schoolId } },
      } = this.props
      const tokenType = getItem('token_type')
      const accessToken = getItem('access_token')
      const expirationTime = getItem('expiration_time')
      const isExpired = expirationTime < Date.now()
      const { userAgent } = navigator

      if (!isAuthenticated) {
        if (userAgent.match(/classting_android/i) || userAgent.match(/classting_iphone/i)) {
          // Webview
          window.setToken = (token) => {
            if (!token) {
              closeWebView()
            } else {
              setItem('token_type', 'bearer')
              setItem('access_token', token)
              onMount({ tokenType: 'bearer', accessToken: token, connectedSchool: schoolId })
            }
          }
          getTokenFromNative()
        } else if (location.hash) {
          // Desktop or mobile browser
          const params = queryString.parse(location.hash)
          const items = {
            token_type: params.token_type,
            access_token: params.access_token,
            expiration_time: Date.now() + params.expires_in * 1000,
          }
          forEach(items, (value, key) => setItem(key, value))
          onMount({
            tokenType: params.token_type,
            accessToken: params.access_token,
            connectedSchool: schoolId,
          })
        } else if (!accessToken || (accessToken && isExpired)) {
          // Desktop or mobile browser
          const params = [
            `client_id=${CLASSTING_CLIENT_ID}`,
            'scope=public_profile',
            'response_type=token',
            `redirect_uri=${window.location.href}`,
          ]
          window.location.href = `${CLASSTING_AUTH_URL}/authorize?${join(params, '&')}`
        } else if (accessToken && !isExpired) {
          onMount({ tokenType, accessToken, connectedSchool: schoolId })
        }
      } else if (connectedSchool !== schoolId) {
        onMount({ tokenType, accessToken, connectedSchool: schoolId })
      }
    }

    render() {
      const { isAuthenticated } = this.props

      return (
        isAuthenticated
          ? <WrappedComponent {...this.props} />
          : <Loader />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(EnhancedComponent)
}

export default withAuth

import { http } from '@/libs'
import { CLASSTING_API_URL } from '@/constants'

export const fetchBlob = async ({ url }) => {
  const { data } = await http.get(url, {
    responseType: 'blob',
  })
  return data
}

export function* fetchGuardianContactNewsletter({ token }) {
  const response = yield http.get(`${CLASSTING_API_URL}/guardian_contact_newsletters?token=${token}`)
  return response
}

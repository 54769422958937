import React from 'react'
// import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
// import { CircularProgress } from '@material-ui/core'

const preloader = keyframes`
  100% { transform: scale(2); }
`

// const Loaderbox = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: ${({ height }) => height};
// `

// const Loader = ({ height }) => (
//   <Loaderbox height={height}>
//     <CircularProgress />
//   </Loaderbox>
// )

const LoaderBox = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 100000;
`

const LoaderElement = styled.span`
  border-radius: 100%;
  border: 5px solid #00c896;
  opacity: 1;
  margin: calc(5px * 2);
  &:nth-child(1) {
    animation: ${preloader} .6s ease-in-out alternate infinite;
  }
  &:nth-child(2) {
    animation: ${preloader} .6s ease-in-out alternate .2s infinite;
  }

  &:nth-child(3) {
    animation: ${preloader} .6s ease-in-out alternate .4s infinite;
  }
`

const Loader = () => (
  <LoaderBox>
    <LoaderElement />
    <LoaderElement />
    <LoaderElement />
  </LoaderBox>
)

Loader.propTypes = {
  // height: PropTypes.string,
}

Loader.defaultProps = {
  // height: '100%',
}

export default Loader

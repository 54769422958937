import firebase from 'firebase/app'
import { forEach, reduce } from 'lodash'

export const onAuthStateChanged = callback => firebase.auth().onAuthStateChanged(callback)

export function* firebaseGetCurrentUser() {
  const currentUser = yield firebase.auth().currentUser
  return currentUser
}

export const firebaseSignIn = token => firebase.auth().signInWithCustomToken(token)

export function* firebaseGetDoc({ path }) {
  const ref = firebase.firestore().doc(path)
  const doc = yield ref.get()
  return doc
}

export function* firebaseGetDocs({
  path, where, orderBy, limit,
}) {
  const ref = firebase.firestore().collection(path)
  let querySnapshot
  if (!where && !orderBy && !limit) {
    querySnapshot = yield ref.get()
  } else if (where && !orderBy && !limit) {
    querySnapshot = yield ref
      .where(where.fieldPath, where.opStr, where.value)
      .get()
  } else if (!where && orderBy && !limit) {
    querySnapshot = yield ref
      .orderBy(orderBy.fieldPath, orderBy.directionStr)
      .get()
  } else if (!where && !orderBy && limit) {
    querySnapshot = yield ref
      .limit(limit)
      .get()
  } else if (where && orderBy && !limit) {
    querySnapshot = yield ref
      .where(where.fieldPath, where.opStr, where.value)
      .orderBy(orderBy.fieldPath, orderBy.directionStr)
      .get()
  } else if (where && !orderBy && limit) {
    querySnapshot = yield ref
      .where(where.fieldPath, where.opStr, where.value)
      .limit(limit)
      .get()
  } else if (!where && orderBy && limit) {
    querySnapshot = yield ref
      .orderBy(orderBy.fieldPath, orderBy.directionStr)
      .limit(limit)
      .get()
  } else {
    querySnapshot = yield ref
      .where(where.fieldPath, where.opStr, where.value)
      .orderBy(orderBy.fieldPath, orderBy.directionStr)
      .limit(limit)
      .get()
  }

  return querySnapshot
}

export function* firebaseSetDoc({ path, data }) {
  const ref = firebase.firestore().doc(path)
  yield ref.set(data)
}

export function* firebaseAddDoc({ path, data }) {
  const ref = firebase.firestore().collection(path)
  const response = yield ref.add(data)
  return response
}

export function* firebasePutFile({ path, file }) {
  const ref = firebase.storage().ref(path)
  yield ref.put(file)
}

export function* firebaseGetDownloadURL({ path }) {
  const url = yield firebase.storage().ref().child(path).getDownloadURL()
  return url
}

export function* firebaseBatch(operations) {
  const batch = firebase.firestore().batch()
  forEach(operations, ({ method, path, data }) => {
    const ref = firebase.firestore().doc(path)
    const patterns = {
      set: () => batch[method](ref, data, { merge: true }),
      update: () => batch[method](ref, data),
      delete: () => batch[method](ref),
    }
    patterns[method]()
  })
  yield batch.commit()
}

export function* firebaseRunTransaction({ path, data }) {
  yield firebase.firestore().runTransaction(async (transaction) => {
    const ref = firebase.firestore().doc(path)
    const doc = await transaction.get(ref)
    if (!doc.exists) {
      const newData = reduce(data, (acc, cur, key) => (
        Array.isArray(cur)
          ? {
            ...acc,
            [key]: reduce(cur, (innerAcc, innerCur) => ({ ...innerAcc, [innerCur]: 1 }), {}),
          }
          : { ...acc, [key]: { [cur]: 1 } }
      ), {})
      transaction.set(ref, newData, { merge: true })
    } else {
      const prevData = doc.data()
      const newData = reduce(data, (acc, cur, key) => {
        if (Array.isArray(cur)) {
          return {
            ...acc,
            [key]: reduce(cur, (innerAcc, innerCur) => {
              if (prevData[key]) {
                return (
                  prevData[key][innerCur]
                    ? {
                      ...innerAcc,
                      [innerCur]: prevData[key][innerCur] + 1,
                    }
                    : {
                      ...innerAcc,
                      [innerCur]: 1,
                    }
                )
              }
              return {
                ...innerAcc,
                [innerCur]: 1,
              }
            }, {}),
          }
        }
        return {
          ...acc,
          [key]: prevData[key]
            ? { ...prevData[key], [cur]: prevData[key][cur] ? prevData[key][cur] + 1 : 1 }
            : { [cur]: 1 },
        }
      }, {})
      transaction.update(ref, newData)
    }
  })
}

export function* firebaseCountTransaction({ path }) {
  yield firebase.firestore().runTransaction(async (transaction) => {
    const ref = firebase.firestore().doc(path)
    const doc = await transaction.get(ref)
    if (!doc.exists) {
      transaction.set(ref, { recordCount: 1 }, { merge: true })
    } else {
      const newRecordCount = doc.data().recordCount + 1
      const newProgress = newRecordCount === doc.data().targetCount ? 'CLOSED' : 'DISTRIBUTED'
      transaction.update(ref, { recordCount: newRecordCount, progress: newProgress })
    }
  })
}

export const getFirestoreTimeStamp = () => firebase.firestore.FieldValue.serverTimestamp()

import { withAuth } from '@/enhancers'
import Newsletter from './components'

export default {
  key: 'newsletter',
  isPrivate: true,
  exact: true,
  path: '/schools/:schoolId/school-newsletters/:newsletterId/:token',
  component: withAuth(Newsletter),
}

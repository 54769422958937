import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'
import {
  Button, Dialog, DialogContent, Modal,
} from '@/components'
import { ANDROID_SCHEME, HOST_URL } from '@/constants'
import { isIOS, isAndroid, daumtools } from '@/libs'
import { ctAppLogo } from '@/assets'
import { clearfix } from '@/styles'

const Wrapper = styled.main`
  padding: 50px 0;
  text-align: center;
`

const Img = styled.img`
  margin-bottom: 30px;
`

const Figcaption = styled.figcaption`
  width: 220px;
  margin: 0 auto 15px;
  font-size: 16px;
  font-weight: 500;
`

const Paragraph = styled.p`
  max-width: 220px;
  margin: 0 auto 40px;
  font-size: 16px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 190px;
  margin: 0 auto 10px;
`

const Guidance = styled.p`
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
`

const DecoratedLink = styled(Link)`
  text-decoration: underline;
`

const ClearfixedDialogContent = styled(DialogContent)`
  ${clearfix};
`

const ConfirmationButton = styled(MuiButton)`
  && {
    position: relative;
    top: 12px;
    left: 18px;
    float: right;
    width: 42px;
    min-width: initial;
    min-height: initial;
    padding: 0;
  }
`

class Main extends Component {
  handleClick = () => {
    const { summary: { schoolId, newsletterId }, token } = this.props
    const encodedURI = encodeURI(`${HOST_URL}/schools/${schoolId}/school-newsletters/${newsletterId}/${token}`)
    if (isAndroid()) {
      daumtools.web2app({
        urlScheme: '', // iphone : custom scheme
        intentURI: `intent://link?hide_navi=1&url=${encodedURI}#Intent;scheme=classting;package=${ANDROID_SCHEME};end`, // android : intent URI
        appName: 'classting', // application Name (ex. facebook, twitter, daum)
        storeURL: 'market://details?id=com.Classting', // app store URL
        willInvokeApp: () => {}, // function for logging
        onAppMissing: () => {}, // fallback function (default. move to appstore)
        onUnsupportedEnvironment: () => {}, // fallback function
      })
    } else if (isIOS()) {
      daumtools.web2app({
        urlScheme: `classting://link?hide_navi=1&url=${encodedURI}`, // iphone : custom scheme
        intentURI: '', // android : intent URI
        appName: 'classting', // application Name (ex. facebook, twitter, daum)
        storeURL: 'http://goo.gl/88kSr', // app store URL
        willInvokeApp: () => {}, // function for logging
        onAppMissing: () => {}, // fallback function (default. move to appstore)
        onUnsupportedEnvironment: () => {}, // fallback function
      })
    }
  }

  redirectToStore = () => {
    if (isAndroid()) {
      window.location.href = 'market://details?id=com.Classting'
    } else if (isIOS()) {
      window.location.href = 'http://goo.gl/88kSr'
    }
  }

  render() {
    const { handleClick, redirectToStore } = this
    const {
      summary: { schoolId, newsletterId, title },
      token,
    } = this.props
    return (
      <Wrapper>
        <figure>
          <Img src={ctAppLogo} alt="클래스팅 앱 로고" />
          <Figcaption>클래스팅: 내 손안의 우리 반</Figcaption>
        </figure>
        <Paragraph>{title}에 응답하려면 앱 다운로드가 필요합니다.</Paragraph>
        <ButtonGroup>
          <Button
            onClick={handleClick}
            variant="outlined"
            coloured="#00c896"
          >
            앱에서 열기
          </Button>
          <Modal>
            {({ handleOpen, handleClose, open }) => (
              <>
                <Button onClick={handleOpen} coloured="#00c896">다운로드</Button>
                <Dialog open={open} onClose={handleClose}>
                  <ClearfixedDialogContent>
                    <p>가정통신문에 응답하려면 앱 다운로드 후 해당 URL로 다시 접속해주세요.</p>
                    <ConfirmationButton
                      onClick={() => {
                        redirectToStore()
                        handleClose()
                      }}
                      color="primary"
                    >
                      확인
                    </ConfirmationButton>
                  </ClearfixedDialogContent>
                </Dialog>
              </>
            )}
          </Modal>
        </ButtonGroup>
        <Guidance>
          앱 설치가 불가능하신가요? <DecoratedLink to={`/schools/${schoolId}/school-newsletters/${newsletterId}/${token}`}>웹으로 보기</DecoratedLink>
        </Guidance>
      </Wrapper>
    )
  }
}

Main.propTypes = {
  summary: PropTypes.shape({
    schoolId: PropTypes.number,
    newsletterId: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  token: PropTypes.string.isRequired,
}

export default Main

import styled from 'styled-components'
import { DialogContent as MuiDialogContent } from '@material-ui/core'

const DialogContent = styled(MuiDialogContent)`
  &&& {
    overflow: visible;
    padding: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    line-height: 1.5;
  }
`

export default DialogContent

import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@/components'
import Header from './Header'
import MainContainer from './MainContainer'

const Landing = ({ match: { params: { token } } }) => (
  <Layout header={<Header />}>
    <MainContainer token={token} />
  </Layout>
)

Landing.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default Landing

export const FETCH_NEWSLETTER_SUMMARY_REQUEST = 'FETCH_NEWSLETTER_SUMMARY_REQUEST'
export const FETCH_NEWSLETTER_SUMMARY_SUCCESS = 'FETCH_NEWSLETTER_SUMMARY_SUCCESS'
export const FETCH_NEWSLETTER_SUMMARY_FAILURE = 'FETCH_NEWSLETTER_SUMMARY_FAILURE'

export const fetchNewsletterSummaryRequest = payload => ({
  type: FETCH_NEWSLETTER_SUMMARY_REQUEST,
  payload,
})
export const fetchNewsletterSummarySuccess = payload => ({
  type: FETCH_NEWSLETTER_SUMMARY_SUCCESS,
  payload,
})
export const fetchNewsletterSummaryFailure = payload => ({
  type: FETCH_NEWSLETTER_SUMMARY_FAILURE,
  payload,
})

import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#00c896',
    },
  },
  typography: {
    useNextVariants: true,
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: [
      '"Apple SD Gothic Neo"',
      '"Nanum Gothic"',
      '"나눔고딕"',
      'NanumGothic',
      '"돋움"',
      'Dotum',
      '"굴림"',
      'Gulim',
      'Helvetica',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    lineHeight: 1.3,
  },
})

import { Component } from 'react'
import PropTypes from 'prop-types'

class Modal extends Component {
  state = { open: false }

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })

  render() {
    const { handleOpen, handleClose } = this
    const { open } = this.state
    const { children } = this.props
    return (
      children({ handleOpen, handleClose, open })
    )
  }
}

Modal.propTypes = {
  children: PropTypes.func.isRequired,
}

export default Modal

import { Component } from 'react'
import { CLASSTING_URL } from '@/constants'

class Home extends Component {
  componentDidMount() {
    window.location.href = CLASSTING_URL
  }

  render() {
    return null
  }
}

export default Home

/* eslint-disable no-alert */
export function isIOS() {
  const { userAgent } = window.navigator

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return true
  }

  return false
}

export function isAndroid() {
  const { userAgent } = window.navigator

  if (userAgent.match(/android/i)) {
    return true
  }

  return false
}

export const callNative = (url) => {
  if (isIOS()) {
    window.location = url
  } else if (!window.ctandroid) {
    window.prompt(url)
  } else {
    window.ctandroid.submitFromWeb(url)
  }
}

export const getTokenFromNative = () => callNative('ctjscall://authorize?source=classting&callback=setToken')

export const closeWebView = () => callNative('ctjscall://close?source=classting')

import {
  SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE,
} from './authActions'

export const initialState = {
  isAuthenticated: false,
  profile: {},
  error: {},
  tokenType: '',
  accessToken: '',
  connectedSchool: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        tokenType: payload.tokenType,
        accessToken: payload.accessToken,
        connectedSchool: payload.connectedSchool || '',
      }
    case SIGN_IN_SUCCESS:
      console.log('success')
      console.log('profile', payload.profile)
      return {
        ...state,
        isAuthenticated: true,
        profile: payload.profile,
      }
    case SIGN_IN_FAILURE:
      return {
        ...state,
        error: payload.error,
      }
    case SIGN_OUT_REQUEST:
      return {
        ...state,
      }
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        tokenType: '',
        accessToken: '',
        connectedSchool: '',
      }
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        error: payload.error,
      }
    default:
      return state
  }
}

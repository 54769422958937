import { injectGlobal } from 'styled-components'

// TODO: styled-components v4 업데이트 이후 createGlobalStyle로 대체 요망
export default injectGlobal`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Apple SD Gothic Neo', 'Nanum Gothic', 나눔고딕, NanumGothic, 돋움, Dotum, 굴림, Gulim, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
  }

  figure {
    margin: 0;
  }
`

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE'

export const signInRequest = payload => ({
  type: SIGN_IN_REQUEST,
  payload,
})
export const signInSuccess = payload => ({
  type: SIGN_IN_SUCCESS,
  payload,
})
export const signInFailure = payload => ({
  type: SIGN_IN_FAILURE,
  payload,
})

export const signOutRequest = payload => ({
  type: SIGN_OUT_REQUEST,
  payload,
})
export const signOutSuccess = payload => ({
  type: SIGN_OUT_SUCCESS,
  payload,
})
export const signOutFailure = payload => ({
  type: SIGN_OUT_FAILURE,
  payload,
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Loader } from '@/components'
import Main from './Main'
import { fetchNewsletterSummaryRequest } from '../landingActions'

const mapStateToProps = ({ landing }) => ({
  isLoading: landing.isLoading,
  summary: landing.summary,
})

const mapDispatchToProps = {
  onMount: fetchNewsletterSummaryRequest,
}

class MainContainer extends Component {
  componentDidMount() {
    const { onMount, token } = this.props
    onMount({ token })
  }

  render() {
    const { isLoading } = this.props
    return (
      isLoading ? <Loader /> : <Main {...this.props} />
    )
  }
}

MainContainer.propTypes = {
  onMount: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MainContainer)
